// import logo from './logo.svg';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from "@material-ui/core/CssBaseline";
//import { MaterialTheme } from './Pattern.js'
import history from './history'
import './App.css';

import Home from './home/home'

function App() {
  return (
    <ThemeProvider >
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
