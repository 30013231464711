import React, { Component } from "react";
import Footer from '../components/footer/footer'
import './home.css'
import backend_api from '../api_config'

class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            regularRecpie: '',
            vegetarianRecipe: '',
            veganRecipe: '',
            glutenFreeRecipe: '',
            ketogenicRecipe: '',
            lactoVegetarianRecipe: '',
            ovoVegetarianRecipe: '',
            pescetarianRecipe: '',
            paleoRecipe: '',
            primalRecipe: '',
            whole30Recipe: ''
        }
    }
    
    componentDidMount(){
        const api = 'https://saltoast.herokuapp.com'
        const config = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
        console.log('backend_api: ', backend_api)
        console.log('envs? ', process.env)
        fetch(`${api}/recipe`, config)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    regularRecpie: data.recipe_list[1],
                    vegetarianRecipe: data.recipe_list[2],
                    veganRecipe: data.recipe_list[3],
                    glutenFreeRecipe: data.recipe_list[4],
                    ketogenicRecipe: data.recipe_list[5],
                    lactoVegetarianRecipe: data.recipe_list[6],
                    ovoVegetarianRecipe: data.recipe_list[7],
                    pescetarianRecipe: data.recipe_list[8],
                    paleoRecipe: data.recipe_list[9],
                    primalRecipe: data.recipe_list[10],
                    whole30Recipe: data.recipe_list[11]
                })
            });
    }

    donateClick () {
        console.log('you clicked me...')
    }
    render () {
        console.log('this.state: ', this.state)
        
      return (
        <div className="home-workspace">
            <div className = 'tag-line'>
                <h1>Think Less, Cook More</h1>
            </div>
            <div className='home-title'>
                <h2>Saltoast</h2>
                <h3>Random recipe everyday for various diets</h3>
                <div className="line-break"></div>
                <p>Have you ever finished up your day and had no clue what to make for dinner? You are not alone!
                    This webpage was built so on those days where you don't have a plan you can find a recipe to
                    cook for dinner. Each day this page will populate with a few new recipes for various dietary
                    lifestyle choices. Enjoy!
                </p>
                {/* <div className='donate'>
                    <p>Donate to the project</p>
                    <button onClick={this.donateClick}>Donate</button>
                </div> */}
            </div>
            <div className = 'recipe-section '>
                <div className = 'recipe-display row'>
                        <div className = 'col-md-2' />
                        <div className = 'recipe entree-recipe col-12 col-md-8'>
                            <h3>Entree</h3>
                            <img id = "recipe" alt="Regular Recipe of the Day" src = {this.state.regularRecpie} />
                        </div>
                        <div className = 'col-md-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe vegetarian-recipe col-8'>
                            <h3>Vegetarian</h3>
                            <img id = "recipe" alt="Vegetarian Recipe of the Day" src = {this.state.vegetarianRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe vegan-recipe col-8'>
                            <h3>Vegan</h3>
                            <img id = "recipe" alt="Vegan Recipe of the Day" src = {this.state.veganRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe gluten-free-recipe col-8'>
                            <h3>Gluten-Free</h3>
                            <img id = "recipe" alt="Gluten-Free Recipe of the Day" src = {this.state.glutenFreeRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe ketogenic-recipe col-8'>
                            <h3>Ketogenic</h3>
                            <img id = "recipe" alt="Keto Recipe of the Day" src = {this.state.ketogenicRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe lacto-vegetarian-recipe col-8'>
                            <h3>Lacto-Vegetarian</h3>
                            <img id = "recipe" alt="Lacto-Vegetarian Recipe of the Day" src = {this.state.lactoVegetarianRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe ovo-vegetarian-recipe col-8'>
                            <h3>Ovo-Vegetarian</h3>
                            <img id = "recipe" alt="Ovo-Vegetarian Recipe of the Day" src = {this.state.ovoVegetarianRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe pescatarian-recipe col-8'>
                            <h3>Pescatarian</h3>
                            <img id = "recipe" alt="Pescatarian Recipe of the Day" src = {this.state.pescetarianRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe paleo-recipe col-8'>
                            <h3>Paleo</h3>
                            <img id = "recipe" alt="Paleo Recipe of the Day" src = {this.state.paleoRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe primal-recipe col-8'>
                            <h3>Primal</h3>
                            <img id = "recipe" alt="Primal Recipe of the Day" src = {this.state.primalRecipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
                <div className = 'recipe-display row'>
                        <div className = 'col-2' />
                        <div className = 'recipe whole30-recipe col-8'>
                            <h3>Whole30</h3>
                            <img id = "recipe" alt="Whole30 Recipe of the Day" src = {this.state.whole30Recipe} />
                        </div>
                        <div className = 'col-2' />
                </div>
            </div>
            <Footer/>
        </div>
      )
    }
  }
  
  export default Home;