import React, { Component } from "react";

import './footer.css'

class Footer extends Component {

    render () {
        const date = new Date()
        const year = date.getFullYear()
        return (
            <div className="footer row">
                <div className = 'col-4'>Made with <i className = 'fa fa-heart'></i></div>
                <div className = 'col-4'></div>
                <div className = 'col-4'>Copyright {year}</div>
            </div>
        )
    }
};

export default Footer;
